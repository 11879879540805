<template>
    <v-container>
        <validation-observer tag="div" ref="observer">
            <v-card v-if="form.address" class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Rechnungsadresse</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <zw-autocomplete v-model="form.address.prefix"
                                             name="prefix"
                                             label="Anrede"
                                             :items="getPrefixes() | optionsVL"
                                             validation="required"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-autocomplete v-model="form.address.title"
                                             name="title"
                                             label="Titel"
                                             :items="getTitles() | optionsVL"
                                             validation="required"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field v-model="form.address.firstname"
                                           name="firstname"
                                           label="Vorname"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field v-model="form.address.lastname"
                                           name="lastname"
                                           label="Nachname"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="PLZ"
                                           name="zip"
                                           v-model="form.address.zip"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Ort"
                                           v-model="form.address.city"
                                           name="city"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Straße, Nr."
                                           v-model="form.address.address"
                                           name="address"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Adressezusatz"
                                           name="additional_address"
                                           v-model="form.address.additional_address"
                            ></zw-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="!authorized()">
                        <v-col cols="12" sm="12" md="12">
                            <zw-checkbox v-model="form.register"
                                         name="register"
                                         label="Ich möchte ein Konto bei KURWELTEN anmelden"
                            ></zw-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.register && !authorized()">
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="E-Mail (als Benutzername)"
                                           name="email"
                                           v-model="form.email"
                                           validation="required|email"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Passwort"
                                           name="password"
                                           v-model="form.password"
                                           validation="required"
                                           ref="password"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Passwort wiederholen"
                                           name="password2"
                                           v-model="form.password2"
                                           :validation="'required|confirmed:password'"
                            ></zw-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Buchungsübersicht</div>
                        </v-col>
                    </v-row>

                    <template v-for="(person,index) in persons">
                        <v-row :key="index">
                            <v-col>
                                <v-card
                                    color="#D3D3D3"
                                >
                                    <v-card-title class="text-h5">Person {{ index + 1 }}: {{ person.name }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-card
                                                    color="#808080"
                                                    dark
                                                >
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col class="pb-0" cols="12" sm="8" md="8">
                                                                {{ prices[person.index].room_name }}
                                                                <span
                                                                    v-if="prices[person.index].food_name"> (mit {{
                                                                        prices[person.index].food_name
                                                                    }})</span>
                                                            </v-col>
                                                            <v-col class="text-right pb-0" cols="12" sm="4" md="4">
                                                                {{ prices[person.index].room_and_food_price }} €
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col class="pb-0" cols="12" sm="8" md="8">Anreise:
                                                                {{ prices[person.index].transfer_name }}
                                                            </v-col>
                                                            <v-col class="text-right pb-0" cols="12" sm="4" md="4">
                                                                {{ prices[person.index].transfer_price }} €
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col class="pb-0" cols="12" sm="8" md="8">
                                                                Versicherung:
                                                                {{ prices[person.index].insurance_name }}
                                                                <div v-if="prices[person.index].corona">
                                                                    +{{ prices[person.index].corona.name }}
                                                                </div>
                                                                <div v-if="prices[person.index].sb">
                                                                    +{{ prices[person.index].sb.name }}
                                                                </div>
                                                            </v-col>
                                                            <v-col class="text-right pb-0" cols="12" sm="4" md="4">
                                                                {{ prices[person.index].insurance_price }} €
                                                            </v-col>
                                                        </v-row>
                                                        <v-row
                                                            v-if="prices[person.index].wellnesses || prices[person.index].cures">
                                                            <v-col class="pb-0" cols="12" sm="12" md="12">
                                                                Zusatzleistungen:
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-for="wellness in prices[person.index].wellnesses"
                                                               :key="wellness.id">
                                                            <v-col class="pb-0" cols="12" sm="8" md="8">
                                                                {{ wellness.name }}
                                                            </v-col>
                                                            <v-col class="text-right pb-0" cols="12" sm="4" md="4">
                                                                {{ wellness.price.toFixed(2) }} €
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-for="cure in prices[person.index].cures" :key="cure.id">
                                                            <v-col class="pb-0" cols="12" sm="8" md="8">
                                                                {{ cure.name }}
                                                            </v-col>
                                                            <v-col class="text-right pb-0" cols="12" sm="4" md="4">
                                                                {{ cure.price.toFixed(2) }} €
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col class="font-weight-bold">Gesamtpreis pro Person
                                                            </v-col>
                                                            <v-col class="text-right font-weight-bold">
                                                                {{ prices[person.index].total }} €
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-card
                                                    color="#808080"
                                                    dark
                                                    style="height: 100%"
                                                >
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col class="font-weight-bold pb-0" cols="12" sm="12"
                                                                   md="12">Weitere Merkmale:
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                {{ prices[person.index].options.join(', ') || '-' }}
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col class="font-weight-bold pb-0" cols="12" sm="12"
                                                                   md="12">Sonderwünsche:
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                {{ prices[person.index].notice || '-' }}
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col class="font-weight-bold pb-0" cols="12" sm="12"
                                                                   md="12">Address:
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                {{ getAddress(person.index) }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>

                    <v-row>
                        <v-col class="text-right">
                            <div class="title">Gesamtpreis für alle Reisende: {{ total.toFixed(2) }} €</div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <zw-checkbox v-model="form.agb"
                                         name="agb"
                                         label="AGB"
                                         validation="required"
                            ></zw-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <zw-checkbox v-model="form.datenschutz"
                                         name="Datenschutz"
                                         label="Datenschutz"
                                         validation="required"
                            ></zw-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <zw-checkbox v-model="form.widerrufsbelehrung"
                                         name="Widerrufsbelehrung"
                                         label="Widerrufsbelehrung"
                                         validation="required"
                            ></zw-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="back">Zurück</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="text-right">
                            <v-row>
                                <v-btn class="mr-2" @click="nextStep(true)">Anfrage senden</v-btn>
                                <v-btn color="primary" @click="nextStep()">Verbindlich buchen</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </validation-observer>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import ZwAutocomplete from "../components/ZwAutocomplete";
import ZwTextField from "../components/ZwTextField";
import ZwCheckbox from "../components/ZwCheckbox";
import price from '../mixins/price'

export default {
    name: 'Finish',
    components: {ZwCheckbox, ZwTextField, ZwAutocomplete},
    mixins: [price],
    data: () => ({
        form: {},
        hash: null,
        persons: [],
        prices: {},
        total: 0,
    }),
    mounted() {
        this.$root.$children[0].showLoading()

        this.form = JSON.parse(localStorage.getItem('form')) || {}
        // this.hash = localStorage.getItem('hash') || null
        // this.hash = this.$route.query.hash || this.hash
        //
        // if (!this.hash) {
        //     this.$router.push({path: '/'})
        // }

        const configuration = this.$store.dispatch('Options/fetchConfiguration')
        const travelersOptions = this.$store.dispatch('Options/fetchOptions')


        Promise.all([configuration, travelersOptions])
            .then(() => {

                const hotelsList = this.$store.dispatch('Hotels/fetchHotels')
                const roomsList = this.$store.dispatch('Travelbox/fetchRooms', this.form.hotel)
                const foodList = this.$store.dispatch('Travelbox/fetchFood', this.form.hotel)
                const transfersList = this.$store.dispatch('Travelbox/fetchTransfers', this.form.hotel)
                const insurancesList = this.$store.dispatch('Travelbox/fetchInsurances', this.form.insurances)
                const curePackages = this.$store.dispatch('Travelbox/fetchCurePackages', this.form.hotel)
                const wellnessPackages = this.$store.dispatch('Travelbox/fetchWellnessPackages', this.form.hotel)

                Promise.all([hotelsList, roomsList, foodList, transfersList, insurancesList, curePackages, wellnessPackages])
                    .then(() => {
                        this.form.travelers.forEach((traveler, index) => {

                                if (this.persons.filter(p => p.index == traveler.index).length == 0) {
                                    this.persons.push({
                                        index: index,
                                        name: traveler.firstname + ' ' + traveler.lastname,
                                        disabled: false,
                                    })

                                    this.prices[index] = this.calculatePricesForPerson(index)

                                    this.total += parseFloat(this.prices[index].total)
                                }
                        })


                        if (!this.form.address.prefix) {
                            this.form.address.prefix = this.form.travelers[0].prefix
                            this.form.address.title = this.form.travelers[0].title
                            this.form.address.firstname = this.form.travelers[0].firstname
                            this.form.address.lastname = this.form.travelers[0].lastname
                            this.form.address.zip = this.form.customer.zip
                            this.form.address.city = this.form.customer.city
                            this.form.address.address = this.form.customer.address
                            this.form.address.additional_address = this.form.customer.additional_address
                        }

                        this.form.travelers.forEach((traveler) => {
                            if (traveler.index) {
                                if (this.persons.filter(p => p.index == traveler.index).length == 0) {
                                    this.persons.push({
                                        index: traveler.index,
                                        name: traveler.firstname + ' ' + traveler.lastname,
                                        disabled: false,
                                    })
                                }
                            }
                        })

                        this.$root.$children[0].hideLoading()
                    })
            })
    },
    methods: {
        ...mapGetters('Travelbox', [
            'getRooms',
            'getFood',
            'getTransfers',
            'getInsurances',
            'getCrnInsurance',
            'getOsbInsurance',
            'getWellnessPackages',
            'getCurePackages'
        ]),
        ...mapGetters('Options', ['getGenders', 'getPrefixes', 'getTitles', 'getStatuses']),
        ...mapGetters('Hotels', ['getHotels']),
        ...mapGetters('Options', ['getConfiguration']),
        back() {
            this.$router.push({path: '/room'})
        },
        nextStep(request = false) {
            this.form.request = request
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$root.$children[0].showLoading()
                    localStorage.setItem('form', JSON.stringify(this.form))

                    this.$router.push('/thanks')
                    this.$store.dispatch('Travelbox/save', this.form)
                        .then(() => {
                            this.$router.push('/thanks')
                        })
                        .catch((error) => {
                            this.$root.$children[0].hideLoading()
                            this.$root.$children[0].showMessage(error.message || 'Server error', 'error')
                        })
                }
            })
        },
        getAddress(personId) {
            const transfer = this.form.transfers.find(transfer => transfer.traveler == personId) || null
            if (transfer) {
                return [transfer.address.address, transfer.address.city, transfer.address.zip, transfer.address.phone, transfer.address.email].join(', ')
            }

            return '-'
        },
        authorized() {
            return localStorage.getItem('token') && localStorage.getItem('token').length ? true : false
        }
    },
}
</script>

<style>
</style>