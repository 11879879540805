import moment from 'moment'

export default {
    methods: {
        calculatePricesForPerson(personId) {
            const hotelSku = this.form.hotel
            const nights = Math.floor(moment.duration(moment(this.form.to).diff(moment(this.form.from))).asDays())
            console.log('Nights: ' + nights)

            const traveler = this.form.travelers[personId]
            const travelerAge = moment.duration(moment(this.form.from).diff(moment(traveler.birthday))).asYears()

            const hotel = this.getHotels().find(hotel => hotel.sku == hotelSku) || null

            if (!hotel) {
                return {}
            }

            const roomData = this.form.rooms.find(room => {
                let found_person = room.persons.find(person => person.index == personId)
                return found_person ? true : false
            }) || {}

            const food = this.getFood().find(food => roomData.food == food.sku) || null
            const room = this.getRooms().find(room => roomData.sku == room.sku) || null

            if (!room) {
                return {}
            }

            let age_discounts = []
            if (hotel) {
                age_discounts = JSON.parse(hotel.customValues.children_discounts) || []
            }

            const discount = age_discounts.find(discount => parseFloat(discount.age_from) <= travelerAge && parseFloat(discount.age_to) >= travelerAge) || null

            console.log('Age discount: ' + discount)
            let priceRow = null
            if (room) {
                priceRow = room.article_prices.find(row => row.start <= this.form.to && row.end > this.form.to) || null
            }
            console.log('Price row: ' + priceRow)

            let roomPrice, foodPrice = 0
            if (priceRow) {
                roomPrice = parseFloat(priceRow.sales_price)
            }
            console.log('Room price: ' + roomPrice)
            if (food) {
                foodPrice = parseFloat(food.price_vk) || 0
                const food_age_discounts = JSON.parse(food.customValues.children_discounts) || []
                const food_discount = food_age_discounts.find(discount => parseFloat(discount.age_from) <= travelerAge && parseFloat(discount.age_to) >= travelerAge) || null

                if (food_discount) {
                    if (food_discount.type == 'percent') {
                        foodPrice = foodPrice * (1 - parseFloat(food_discount.value) / 100)
                    } else {
                        foodPrice = parseFloat(food_discount.value)
                    }
                }
            }
            if (discount) {
                if (discount.type == 'percent') {
                    roomPrice = roomPrice * (1 - parseFloat(discount.value) / 100)
                } else {
                    roomPrice = parseFloat(discount.value)
                }
            }

            roomPrice = roomPrice * nights
            foodPrice = foodPrice * nights

            const passengersCount = this.getPassengersInfo(personId)
            const busCount = passengersCount.bus
            const limoCount = passengersCount.limo
            const personTransfer = passengersCount.transfer
            const wheelchair = passengersCount.wheelchair
            const walker = passengersCount.walker

            const transfer_bus_person = this.getTransfers().find(transfer => transfer.sku.startsWith('TRNS_BUS_PP_')) || null
            let transfer_bus_full = this.getTransfers().find(transfer => transfer.sku.startsWith('TRNS_BUS_VL_')) || null
            const transfer_limo = this.getTransfers().find(transfer => transfer.sku.startsWith('TRNS_LIMO_')) || null

            if (hotel.position == 'Ostsee') {
                transfer_bus_full = null
            }

            let busPrice = 0

            if (transfer_bus_full) {
                busPrice = parseFloat(transfer_bus_full.price_vk)
            } else if (transfer_bus_person) {
                busPrice = parseFloat(transfer_bus_person.price_vk) * (busCount < 3 ? 3 : busCount)
            }

            const MAX_PASSENGERS_IN_CAR = 3
            let carsCount = 0
            if (limoCount > MAX_PASSENGERS_IN_CAR) {
                carsCount = Math.ceil(limoCount / MAX_PASSENGERS_IN_CAR)
            }
            let limoPrice = 0
            if (carsCount) {
                limoPrice = carsCount * parseFloat(transfer_limo.price_vk)
            }

            const busPricePP = busCount ? busPrice / busCount : 0
            const limoPricePP = limoCount ? limoPrice / limoCount : 0
            const currentTransfer = this.form.same_transfer ? this.form.transfers[0] : this.form.transfers.find(transfer => transfer.traveler == traveler.index)

            let transfer_price = 0
            let transfer_name = null
            if (personTransfer == 'BUS') {
                let increase = this.getZipPriceIncrease(currentTransfer.address.zip)
                transfer_price = busPricePP + increase
                if (traveler.pets) {
                    transfer_price += 10
                }

                if (transfer_bus_full) {
                    transfer_name = transfer_bus_full.name ?? ''
                } else if (transfer_bus_person) {
                    transfer_name = transfer_bus_person.name ?? ''
                }
            }
            if (personTransfer == 'LIMO') {
                let increase = this.getZipPriceIncrease(currentTransfer.address.zip)
                transfer_price = limoPricePP + increase
                if (traveler.pets) {
                    transfer_price += 10
                }
                transfer_name = transfer_limo.name ?? ''
            }

            let personServices = []
            if (this.form) {
                const services = this.form.services.map((service, index) => {
                    return {
                        ...service,
                        ...{index: index}
                    }
                })
                personServices = services.filter(service => {
                    let found_person = service.persons.find(person => person.index == personId)
                    return found_person ? true : false
                }) || []
            }

            let wellnesses = []
            let cures = []

            let wellness_total_price = 0
            let cure_total_price = 0

            if (personServices) {
                personServices.forEach(service => {
                    if (service.wellness && service.wellness_range && service.wellness_range.length) {
                        let range_from = service.wellness_range[0] || null
                        let range_till = service.wellness_range[1] || range_from

                        let duration = 1
                        if (range_from && range_till) {
                            duration = moment.duration(moment(range_till).diff(moment(range_from))).asDays()
                            if (duration < 1) {
                                duration = 1
                            }
                        }

                        let wellness = this.getWellnessPackages().find(p => p.sku == service.wellness)
                        wellnesses.push({
                            index: service.index,
                            id: wellness.id,
                            sku: wellness.sku,
                            name: wellness.name,
                            price_vk: wellness.price_vk,
                            duration: duration,
                            price: parseFloat(wellness.price_vk) * duration,
                        })

                        wellness_total_price += parseFloat(wellness.price_vk) * duration
                    }

                    if (service.cure && service.cure_range && service.cure_range.length) {

                        let range_from = service.cure_range[0] || null
                        let range_till = service.cure_range[1] || range_from

                        let lastDay = moment(range_till);
                        let firstDay = moment(range_from);
                        let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 - (firstDay.day() - lastDay.day()) * 2) / 7;

                        if (lastDay.day() == 6) calcBusinessDays--;//SAT
                        if (firstDay.day() == 0) calcBusinessDays--;//SUN

                        let duration = calcBusinessDays
                        if (duration < 1) {
                            duration = 1
                        }

                        let cure = this.getCurePackages().find(p => p.sku == service.cure)
                        cures.push({
                            id: cure.id,
                            index: service.index,
                            sku: cure.sku,
                            name: cure.name,
                            price_vk: cure.price_vk,
                            duration: duration,
                            price: parseFloat(cure.price_vk) * duration,
                        })

                        cure_total_price += parseFloat(cure.price_vk) * duration
                    }
                })
            }

            let insuranceId = null
            let corona = null
            let sb = null

            if (this.form.insurances.length) {
                if (this.form.same_insurance) {
                    insuranceId = this.form.insurances[0].insurance
                    corona = this.form.insurances[0].corona ? true : false
                    sb = this.form.insurances[0].sb ? true : false
                } else {
                    this.form.insurances.forEach((insurance) => {
                        if (insurance.traveler == personId) {
                            insuranceId = insurance.insurance
                            corona = insurance.corona ? true : false
                            sb = insurance.sb ? true : false
                        }
                    })
                }
            }

            const costWithoutInsurance = roomPrice + foodPrice + transfer_price + wellness_total_price + cure_total_price

            const insurance = this.getInsurances().find(i => i.id == insuranceId) || null
            const insurancePrices = insurance ? JSON.parse(insurance.customValues['insurance_prices']) : []

            let insurancePrice = insurance ? parseFloat(this.getInsurancePrice(insurancePrices, costWithoutInsurance)) : 0
            if (corona && this.getCrnInsurance()) {
                const insuranceCoronaPrices = JSON.parse(this.getCrnInsurance().customValues['insurance_prices'])
                insurancePrice += parseFloat(this.getInsurancePrice(insuranceCoronaPrices, costWithoutInsurance) || 0)
            }
            if (sb && this.getOsbInsurance()) {
                const insuranceOsbPrices = JSON.parse(this.getOsbInsurance().customValues['insurance_prices'])[insurance.name.includes('ab 65') ? 'after65' : 'until65']
                insurancePrice += parseFloat(this.getInsurancePrice(insuranceOsbPrices, costWithoutInsurance) || 0)
            }

            let options = []
            if (wheelchair) {
                options.push('Klapprollstuhl')
            }
            if (walker) {
                options.push('Rollator')
            }
            if (traveler.allergic) {
                options.push('Allergien')
            }
            if (traveler.diabetic) {
                options.push('Diabetiker')
            }
            if (traveler.pets) {
                options.push('Haustiere')
            }

            return {
                room_price: roomPrice.toFixed(2),
                food_price: foodPrice.toFixed(2),
                bus_price: busPricePP.toFixed(2),
                limo_price: limoPricePP.toFixed(2),
                transfer_price: transfer_price.toFixed(2),
                transfer_name: transfer_name,
                insurance_name: insurance ? insurance.name : '',
                corona: corona ? this.getCrnInsurance() : null,
                sb: sb ? this.getOsbInsurance() : null,
                insurance_price: insurancePrice.toFixed(2),
                room_name: room.name,
                food_name: food ? food.name : null,
                room_and_food_price: (roomPrice + foodPrice).toFixed(2),
                traveler: traveler.index,
                traveler_age: travelerAge,
                nights: nights,
                hotel: hotel.sku,
                wellnesses: wellnesses,
                wellness_total_price: wellness_total_price.toFixed(2),
                cures: cures,
                cure_total_price: cure_total_price.toFixed(2),
                options: options,
                notice: roomData.notice,
                total: (roomPrice + foodPrice + transfer_price + insurancePrice + wellness_total_price + cure_total_price).toFixed(2),
            }
        },
        getPassengersInfo(personId) {
            let busCount = 0
            let limoCount = 0
            let transfer = null
            let wheelchair = false
            let walker = false

            if (this.form.transfers.length) {
                if (this.form.same_transfer) {
                    if (this.form.transfers[0].transfer == 'LIMO') {
                        limoCount += this.form.travelers.length
                    }
                    if (this.form.transfers[0].transfer == 'BUS') {
                        busCount += this.form.travelers.length
                    }
                    transfer = this.form.transfers[0].transfer
                    wheelchair = this.form.transfers[0].wheelchair
                    walker = this.form.transfers[0].walker
                } else {
                    this.form.transfers.forEach((t) => {
                        if (t.transfer == 'LIMO') {
                            limoCount += 1
                        }
                        if (t.transfer == 'BUS') {
                            busCount += 1
                        }

                        if (t.traveler == personId) {
                            transfer = t.transfer
                            wheelchair = t.wheelchair
                            walker = t.walker
                        }
                    })
                }
            }

            return {
                'bus': busCount,
                'limo': limoCount,
                'transfer': transfer,
                'wheelchair': wheelchair,
                'walker': walker,
            }
        },
        getZipPriceIncrease(zip) {
            if (zip) {
                const zipCode = parseInt(zip.replace(/[^\d.]/g, ''))
                const indexPrices = this.getConfiguration()['index-prices'] || []

                const found = Object.keys(indexPrices).find(key => {
                    let indexes = key.split('-')

                    return parseInt(indexes[0]) <= zipCode && parseInt(indexes[1]) >= zipCode
                }) || null

                if (found) {
                    return indexPrices[found]
                } else {
                    // todo: return max?
                }
            }
            return 0;
        },
        getInsurancePrice(prices, sum) {
            if (prices) {
                const found = Object.keys(prices).find(key => {
                    let range = key.split('-')

                    return parseInt(range[0]) < sum && parseInt(range[1]) >= sum
                }) || null

                if (found) {
                    return prices[found]
                }
            }

            return 0
        }
    },
}